import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCommentAltDots } from "@fortawesome/pro-regular-svg-icons";
import { faEye } from "@fortawesome/pro-light-svg-icons";
import Defaulter from "../../../types/Defaulter";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import MissedVaccineCell from "./MissedVaccineCell";
import i18next from "i18next";
import { formatDateInArabic } from "../../../utils/formatDateInArabic";
import moment from "moment";

const DefaulterRow: React.FC<{
    defaulter: Defaulter;
}> = ({ defaulter }) => {
    const [showOtherMissedVaccines, setShowOtherMissedVaccines] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const { selectedDefaulters, selectDefaulter, deselectDefaulter } = useContext(SelectedDefaultersContext);
    const checked = selectedDefaulters.indexOf(defaulter.slug) !== -1;

    return (
        <tr className="border-t border-gray-df" >
            <td
                className={`table__cell text-sm text-black41 whitespace-normal sticky px-3 border-t border-gray-df td-firefox ${i18next.language === "ar" ? "border-l right-0 text-right" : "border-r left-0"
                    }`}
            >
                <div className="flex items-center">
                    <div
                        className={`table__checkbox grid place-items-center ${i18next.language === "ar" ? "ml-4" : "mr-4"} ${checked && "border-blue-primary bg-blue-primary"}`}
                        onClick={() => {
                            if (checked) {
                                deselectDefaulter(defaulter.slug);
                            } else {
                                selectDefaulter(defaulter.slug);
                            }
                        }}
                    >
                        {checked && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
                    </div>
                    <p className="whitespace-nowrap">{defaulter.full_name}</p>
                    {defaulter.note && (
                        <div className="ml-3 cursor-pointer text-gray-8d relative">
                            {showNote && (
                                <p
                                    className="absolute w-[176px] bg-white rounded shadow text-xs text-black41 p-2 whitespace-pre-wrap -left-[70px] bottom-6"
                                >
                                    {defaulter.note}
                                </p>
                            )}
                            <FontAwesomeIcon
                                onMouseOver={() => setShowNote(true)}
                                onMouseLeave={() => setShowNote(false)}
                                icon={faCommentAltDots}
                            />
                        </div>
                    )}
                </div>
            </td>
            <td className={`table__cell text-sm text-black41 border-t td-firefox border-gray-df ${i18next.language === "ar" && "text-right"}`} dir="ltr">
                {defaulter.phone}
            </td>
            <td className="table__cell text-sm text-black41 border-t td-firefox border-gray-df">
                {defaulter.national_id}
            </td>
            <td className="table__cell text-sm text-black41 border-t td-firefox border-gray-df whitespace-normal">
                {defaulter.clinic_name}
            </td>
            <td className="table__cell text-sm text-black41 border-t td-firefox border-gray-df whitespace-normal">
                {defaulter.area}
            </td>
            <td className="table__cell text-sm text-black41 border-t td-firefox border-gray-df">
                {defaulter.missed_vaccines.length > 0 && (
                    <MissedVaccineCell
                        missedVaccines={defaulter.missed_vaccines}
                        showOtherMissedVaccines={showOtherMissedVaccines}
                        setShowOtherMissedVaccines={setShowOtherMissedVaccines}
                    />
                )}
            </td>

            <td className="table__cell text-sm text-black41 border-t td-firefox border-gray-df">
                <p>{i18next.language === "ar" && defaulter.last_reminder_sent !== null
                    ? formatDateInArabic(moment(defaulter.last_reminder_sent, "DD/MM/YYYY").format("DD/MM/YYYY"))
                    : defaulter.last_reminder_sent}</p>
            </td>
            <td className="table__cell border-t td-firefox border-gray-df">
                <div
                    className="w-8 h-8 rounded grid place-items-center text-blue-primary hover:bg-blue-e2 cursor-pointer relative mx-4"
                    onMouseOver={() => setShowHint(true)}
                    onMouseLeave={() => setShowHint(false)}
                    onClick={() => history.push(`/defaulters/${defaulter.slug}`)}
                >
                    <FontAwesomeIcon className="m-auto" icon={faEye} />
                    {showHint && (
                        <div className="absolute -top-8 w-16 h-6 grid place-items-center bg-white rounded border border-gray-df">
                            <div className="w-2 h-2 bg-white absolute -bottom-[4px] left-[28px] rotate-45 border-r border-b border-gray-df" />
                            <p className="capitalize text-black41 text-sm">{t("view")}</p>
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default DefaulterRow;
