import React, { useState, useEffect, PropsWithChildren } from "react";
import ChildrenAPI from "./api/childrenAPI";
import i18next from "i18next";
import IdValueType from "../types/IdValueType";
import { ClinicTitle } from "./SearchFilterDefaultersContext";
import ClinicsApi from "../store/api/clinicsAPI";

type ChildrenFilter = {
  vaccines: IdValueType[];
  vaccineStatuses: VaccineStatus[];
  injectionStatuses: VaccineStatus[];
  clinic: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export type VaccineStatus = {
  key: string;
  name: string;
  icon: string;
  color: string;
};

const DEFAULT_CHILDREN_FILTER = {
  vaccines: [],
  vaccineStatuses: [],
  injectionStatuses: [],
  clinic: "",
  dateFrom: null,
  dateTo: null,
};

type SearchFilterChildrenContextType = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  childrenFilter: ChildrenFilter;
  childrenAppliedFilter: ChildrenFilter;
  setChildrenFilter: React.Dispatch<React.SetStateAction<ChildrenFilter>>;
  setChildrenAppliedFilter: React.Dispatch<React.SetStateAction<ChildrenFilter>>;
  vaccines: IdValueType[];
  vaccineStatuses: VaccineStatus[];
  injectionStatuses: VaccineStatus[];
  clinics: ClinicTitle[];
  reset: () => void;
  isAnyFilterApplied: () => boolean;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchFilterChildrenContext = React.createContext<SearchFilterChildrenContextType>({
  searchValue: '',
  setSearchValue: () => { },
  childrenFilter: DEFAULT_CHILDREN_FILTER,
  childrenAppliedFilter: DEFAULT_CHILDREN_FILTER,
  vaccines: [],
  vaccineStatuses: [],
  injectionStatuses: [],
  clinics: [],
  setChildrenFilter: () => {
    //
  },
  setChildrenAppliedFilter: () => {
    ///
  },
  reset: () => {
    //
  },
  isAnyFilterApplied: () => false,
  setLanguage: () => {
    //
  },
});

const SearchFilterChildrenContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [childrenFilter, setChildrenFilter] = useState<ChildrenFilter>(DEFAULT_CHILDREN_FILTER);
  const [childrenAppliedFilter, setChildrenAppliedFilter] = useState<ChildrenFilter>(DEFAULT_CHILDREN_FILTER);
  const [vaccines, setVaccines] = useState<IdValueType[]>([]);
  const [vaccineStatuses, setVaccineStatuses] = useState<VaccineStatus[]>([]);
  const [injectionStatuses, setInjectionStatuses] = useState<VaccineStatus[]>([]);
  const [clinics, setClinics] = useState<ClinicTitle[]>([]);
  const { getDropdownsData } = ChildrenAPI();
  const { getClinicTitles } = ClinicsApi();
  const [language, setLanguage] = useState<string>(i18next.language);

  const reset = () => {
    setChildrenFilter(DEFAULT_CHILDREN_FILTER);
    setChildrenAppliedFilter(DEFAULT_CHILDREN_FILTER);
  };

  useEffect(() => {
    if (language) {
      getDropdownsData(async (response: Response) => {
        const result = await response.json();
        if (response.ok) {
          setVaccines(result.vaccination);
          setVaccineStatuses(result.vaccination_type);
          setInjectionStatuses(result.child_vaccination_status);
        }
      });
    }
    getClinicTitles((clinic) => setClinics(clinic));
  }, [language]);

  const isAnyFilterApplied = () => {
    return (childrenAppliedFilter !== DEFAULT_CHILDREN_FILTER);
  };

  return (
    <SearchFilterChildrenContext.Provider
      value={{
        searchValue,
        setSearchValue,
        childrenFilter,
        childrenAppliedFilter,
        setChildrenFilter,
        setChildrenAppliedFilter,
        vaccines,
        vaccineStatuses,
        injectionStatuses,
        clinics,
        reset,
        isAnyFilterApplied,
        setLanguage,
      }}
    >
      {children}
    </SearchFilterChildrenContext.Provider>
  );
};

export default SearchFilterChildrenContextProvider;
