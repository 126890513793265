import React, { useEffect, ReactNode, useMemo, Dispatch, SetStateAction, useContext } from "react";
import { useTable, usePagination, Column } from "react-table";
import Pagination from "../../UI/Pagination";
import ChildrenType from "../../../types/Children";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChildrenRow from "./ChildrenRow";
import i18next from "i18next";
import { SearchFilterChildrenContext } from "../../../store/SearchFilterChildrenContext";
import {
  IconDefinition,
  faAngleDown,
  faAngleUp,
  faCheckCircle,
  faTimesCircle,
  faClock,
  faBan,
  faSolarSystem,
  faQuestion,
} from "@fortawesome/pro-light-svg-icons";
import { faScrubber } from "@fortawesome/pro-regular-svg-icons";
import { PaginationConfig, SortConfig } from "./Children";

const ChildrenTable: React.FC<{
  staticColumns: Column<ChildrenType>[];
  dynamicColumns: Column<ChildrenType>[];
  data: ChildrenType[];
  paginationConf: PaginationConfig;
  setPaginationConf: Dispatch<SetStateAction<PaginationConfig>>;
  pageCount: number;
  totalDataCount: number;
  currentSortConfig: SortConfig | undefined;
  listIsLoading: boolean;
  pageChanged: (pageIndex: number, pageSize: number) => void;
  sortChanged: (sortConfig: SortConfig) => void;
}> = ({
  staticColumns,
  dynamicColumns,
  data,
  paginationConf,
  setPaginationConf,
  pageCount = 0,
  totalDataCount,
  currentSortConfig,
  listIsLoading,
  pageChanged,
  sortChanged,
}) => {
  const { vaccines, vaccineStatuses, injectionStatuses } = useContext(SearchFilterChildrenContext);
  const columns = useMemo(() => [...staticColumns, ...dynamicColumns], [staticColumns, dynamicColumns]);
  const { getTableProps, getTableBodyProps, headerGroups } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );
  const unsortableColumns = ["enrolled_date"];

  useEffect(() => {
    pageChanged(paginationConf.pageIndex, paginationConf.pageSize);
  }, [paginationConf.pageSize, paginationConf.pageIndex]);

  type IconColorPair = [IconDefinition, string?];
  const statusIconHandler = (status: string): IconColorPair => {
    const statusMap: Record<string, IconColorPair> = {
      // RG: [faScrubber, "#11589a"],
      OP: [faSolarSystem, "#709bc1"],
      DC: [faBan, "#dadada"],
    };
    return statusMap[status] || [faQuestion, "#ffffff"];
  };

  const showVaccineIconHandler = (column: ReactNode) => {
    return (
      <>
        {vaccines.length !== 0 &&
          vaccines.map(
            (vaccine) =>
              vaccine.name === column &&
              vaccine.type.length !== 0 &&
              vaccine.type.map((type, i) => (
                <FontAwesomeIcon
                  key={i}
                  className="mx-1 text-[16px]"
                  style={{ color: statusIconHandler(type)[1] }}
                  icon={statusIconHandler(type)[0]}
                />
              ))
          )}
      </>
    );
  };

  const iconHandler = (iconName: string): IconDefinition => {
    switch (iconName) {
      case "faCheckCircle":
        return faCheckCircle;
      case "faTimesCircle":
        return faTimesCircle;
      case "faClock":
        return faClock;
      case "faBan":
        return faBan;
      case "faScrubber":
        return faScrubber;
      case "faSolarSystem":
        return faSolarSystem;
      default:
        return faQuestion;
    }
  };

  const sortTable = (column: string, isAscending: boolean) => {
    const vaccine = vaccines.find((vacc) => vacc.name === column);
    sortChanged({
      column: column,
      vaccineName: vaccine ? vaccine.name : undefined,
      sortType: isAscending ? "Ascending" : "Descending",
      vaccineId: vaccine?.id,
    });
    setPaginationConf({ ...paginationConf, pageIndex: 0 });
  };

  return (
    <>
      <div className="relative" style={{ visibility: listIsLoading ? "hidden" : "visible" }}>
        <div className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden">
          <table {...getTableProps()} className="w-full border-separate border-spacing-0">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      scope="col"
                      className={` td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${
                        index === 0 && `z-20 ${i18next.language === "ar" ? "right-0 border-l" : "left-0 border-r"}`
                      }`}
                      {...column.getHeaderProps()} //i replace this method column.getSortByToggleProps()
                    >
                      {column.render("header") !== " " && (
                        <div className="flex items-center">
                          <h3 className="text-sm font-bold text-black41">{column.render("header")}</h3>
                          <div>{showVaccineIconHandler(column.render("header"))}</div>
                          {!dynamicColumns.find((dc) => column.render("header") === dc.accessor) &&
                            !unsortableColumns.includes(column.id) && (
                              <div className={`flex flex-col ${i18next.language === "ar" ? "mr-4" : "ml-4"} `}>
                                <FontAwesomeIcon
                                  className={`text-sm cursor-pointer ${
                                    currentSortConfig?.column === column.id &&
                                    currentSortConfig?.sortType === "Ascending"
                                      ? "text-black41"
                                      : "text-gray-df"
                                  }`}
                                  icon={faAngleUp}
                                  onClick={() => sortTable(column.id, true)}
                                />
                                <FontAwesomeIcon
                                  className={`text-sm cursor-pointer ${
                                    currentSortConfig?.column === column.id &&
                                    currentSortConfig?.sortType === "Descending"
                                      ? "text-black41"
                                      : "text-gray-df"
                                  }`}
                                  icon={faAngleDown}
                                  onClick={() => sortTable(column.id, false)}
                                />
                              </div>
                            )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.map((child) => (
                <ChildrenRow
                  // key={child.slug}
                  child={child}
                  dynamicColumns={dynamicColumns}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row mt-6 border rounded p-2 w-fit" dir="ltr">
          {[...injectionStatuses, ...vaccineStatuses].map(
            (type, index) =>
              type.key !== "RG" && (
                <div key={type.key || index} className="text-[12px] mr-5">
                  <FontAwesomeIcon
                    className={`mr-1 text-[16px] ${type.key === "M" && ""}`}
                    style={{ color: type.color }}
                    icon={iconHandler(type.icon)}
                  />
                  <span>{type.name}</span>
                </div>
              )
          )}
        </div>
      </div>
      {pageCount > 0 && (
        <Pagination
          dropdownKeys={["5", "10", "20"]}
          previousPage={() => setPaginationConf((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex - 1 }))}
          nextPage={() => setPaginationConf((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex + 1 }))}
          setPageSize={(pageSize) => setPaginationConf((preValue) => ({ ...preValue, pageSize: pageSize }))}
          gotoPage={(_pageNum) => setPaginationConf((preValue) => ({ ...preValue, pageIndex: _pageNum as number }))}
          pageCount={pageCount}
          pageIndex={paginationConf.pageIndex}
          pageSize={paginationConf.pageSize}
          pageLength={data.length}
          totalLength={totalDataCount}
        />
      )}
    </>
  );
};

export default ChildrenTable;
