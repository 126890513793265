import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import ChildrenType from "../../../types/Children";
import MainFooter from "../../UI/MainFooter";
import ChildrenAPI from "../../../store/api/childrenAPI";
import NoResultMessage from "../../UI/NoResultMessage";
import ChildrenTable from "./ChildrenTable";
import SelectedChildren from "./SelectedChildren";
import ResendMessageModal from "./ResendMessageModal";
import { SelectedChildrenContext } from "../../../store/SelectedChildrenContext";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { SnackBarType } from "../../../utils/Constants";
import SearchSection from "../../UI/SearchSection";
import ChildrenFilterSection from "./ChildrenFilterSection";
import { SearchFilterChildrenContext } from "../../../store/SearchFilterChildrenContext";
import PrimaryButton from "../../UI/PrimaryButton";
import saveAs from "file-saver";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { Column } from "react-table";
import i18next from "i18next";
import { getDate } from "../../../utils/getDate";

export type PaginationConfig = {
  pageIndex: number;
  pageSize: number;
};

export type SortConfig = {
  column: string;
  sortType: "Ascending" | "Descending";
  vaccineName?: string;
  vaccineId?: string;
};

const Children = () => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [displayableChildren, setDisplayableChildren] = useState<ChildrenType[]>([]);
  const [showResendMessageModal, setShowResendMessageModal] = useState(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const { getChildren, sendMessage, exportChildren } = ChildrenAPI();
  const [loading, setLoading] = useState(false);
  const [showChildrenLoading, setShowChildrenLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });
  const { selectedChildren, clearSelectedChildren } = useContext(SelectedChildrenContext);
  const { searchValue, setSearchValue, childrenFilter, childrenAppliedFilter, setChildrenAppliedFilter, setLanguage, vaccines } =
    useContext(SearchFilterChildrenContext);
  const [exporting, setExporting] = useState(false);
  const [paginationConf, setPaginationConf] = useState<PaginationConfig>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [pageCount, setPageCount] = useState(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [currLanguage, setCurrLanguage] = useState<string | null>(null);

  useEffect(() => {
    setCurrLanguage(localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).lang : "en");
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(totalDataCount / paginationConf.pageSize));
  }, [totalDataCount, paginationConf]);

  useEffect(() => {
    setLanguage(i18next.language);
    if (currLanguage && currLanguage !== i18next.language) {
      setShowChildrenLoading(true);
      fetchChildren();
      setCurrLanguage(i18next.language);
    }
  }, [i18next.language]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setShowChildrenLoading(true);
    fetchChildren();
  }, [childrenAppliedFilter, paginationConf.pageIndex, paginationConf.pageSize, sortConfig]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      setShowChildrenLoading(true);
      fetchChildren();
    }
  }, [searchValue]);

  function constructQueryParams() {
    let queryParams = [];
    queryParams.push(`page=${paginationConf.pageIndex + 1}`);
    queryParams.push(`&size=${paginationConf.pageSize}`);

    if (searchValue)
      queryParams.push(`&search=${encodeURIComponent(searchValue)}`);

    if (childrenAppliedFilter.vaccines.length > 0)
      queryParams.push(childrenAppliedFilter.vaccines.map((vaccine) => "&vaccination=" + vaccine.id).join(""));

    if (childrenAppliedFilter.vaccineStatuses.length > 0)
      queryParams.push(childrenAppliedFilter.vaccineStatuses.map((status) => "&vaccination_status=" + status.key).join(""));

    if (childrenAppliedFilter.injectionStatuses.length > 0)
      queryParams.push(childrenAppliedFilter.injectionStatuses.map((status) => "&injection_status=" + status.key).join(""));
    if (childrenAppliedFilter.clinic !== "")
      queryParams.push(`&clinic=${childrenAppliedFilter.clinic}`);
    if (childrenAppliedFilter.dateFrom !== null)
      queryParams.push(`&interval_date_after=${getDate(childrenAppliedFilter.dateFrom.toString(), false)}`);
    if (childrenAppliedFilter.dateTo !== null)
      queryParams.push(`&interval_date_before=${getDate(childrenAppliedFilter.dateTo.toString(), false)}`);

    if (sortConfig) queryParams.push(`&ordering=${sortConfig.sortType === "Ascending" ? "" : "-"}${sortConfig.vaccineName ? sortConfig.vaccineName : sortConfig.column}`);

    return `?${queryParams.join("")}`;
  }

  const fetchChildren = () => {
    getChildren(async (response: Response) => {
      const result = await response.json();
      setShowChildrenLoading(false);
      if (response.ok) {
        setTotalDataCount(result.count);
        setDisplayableChildren(result.results);
      }
    }, `children-list/${constructQueryParams()}`);
  };

  const onApply = () => {
    if (childrenFilter.vaccineStatuses.length > 0) {
      setSortConfig(undefined);
    }

    setChildrenAppliedFilter({ ...childrenFilter });
    setPaginationConf({ ...paginationConf, pageIndex: 0 });
  };

  const generateDynamicColumns = () =>
    vaccines
      .filter(vaccine =>
        (!childrenAppliedFilter.vaccines.length || childrenAppliedFilter.vaccines.some(v => v.id === vaccine.id)) &&
        (!childrenAppliedFilter.vaccineStatuses.length || childrenAppliedFilter.vaccineStatuses.some(v =>
          Array.isArray(vaccine.type) ?
            (vaccine.type.includes(v.key) || (childrenAppliedFilter.vaccineStatuses.some(v => v.key === "RG") && vaccine.type.length === 0))
            : v.key === vaccine.type
        )))
      .map(vaccine => ({
        header: vaccine.name,
        accessor: vaccine.name,
      }));

  const _columns: Column<ChildrenType>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessor: "full_name",
      },
      {
        header: t("phone_number"),
        accessor: "phone",
      },
      {
        header: t("national_id"),
        accessor: "national_id",
      },
      {
        header: t("registered_clinic"),
        accessor: "clinic_name",
      },
      {
        header: t("area"),
        accessor: "area",
      },
      {
        header: t("gender"),
        accessor: "gender",
      },
      {
        header: t("enrolled_date"),
        accessor: "enrolled_date",
      },
    ],
    [t]
  );

  const sendMessageHandler = (message: string) => {
    setLoading(true);
    sendMessage(
      selectedChildren.map((selectedChild) => ({ slug: selectedChild })),
      message,
      async (response) => {
        const result = await response.json();
        if (response.ok) {
          setSnackbar({ message: t("message_sent_successfully"), show: true });
          setLoading(false);
          setShowResendMessageModal(false);
          clearSelectedChildren();
          setSnackBarType(SnackBarType.IMPORT);
        } else {
          setLoading(false);
          setSnackBarType(SnackBarType.IMPORTFAILED);
          setSnackbar({ message: result, show: true });
        }
      }
    );
  };

  function exportQueryParams() {
    let queryParams = [];

    if (childrenAppliedFilter.vaccines.length > 0) {
      let vaccineIds = childrenAppliedFilter.vaccines.map((vaccine) => vaccine.id).join(",");
      if (vaccineIds !== "") {
        queryParams.push(`vaccination_list=${vaccineIds}`);
      }
    }

    if (childrenAppliedFilter.injectionStatuses.length > 0) {
      let statusKeys = childrenAppliedFilter.injectionStatuses.map((status) => status.key).join(",");
      if (statusKeys !== "") {
        queryParams.push(`child_vaccination_status=${statusKeys}`);
      }
    }

    if (childrenAppliedFilter.vaccineStatuses.length > 0) {
      let typeKeys = childrenAppliedFilter.vaccineStatuses.map((type) => type.key).join(",");
      if (typeKeys !== "") {
        queryParams.push(`vaccination_status=${typeKeys}`);
      }
    }

    if (childrenAppliedFilter.clinic !== "") {
      queryParams.push(`clinic_slug=${childrenAppliedFilter.clinic}`);
    }

    return `?${queryParams.join("&")}`;
  }

  const exportChildrenHandler = () => {
    setExporting(true);
    exportChildren(exportQueryParams(), async (response) => {
      if (response.ok) {
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(blob, ` "children".csv`);
            setExporting(false);
            setSnackbar({
              show: true,
              message: t("data_exported_successfully"),
            });
          } catch (e) {
            console.log("error:file not found");
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    });
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${snackBarType === SnackBarType.IMPORTFAILED ? "#D11C1C" : "#03A678"}`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      {exporting && <LoadingSpinner onBackdrop={() => setExporting(false)} titleKey={t("exporting_to_csv")} />}

      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">{t("children")}</h3>
          <div className="mt-2 md:mt-0">
            {!showChildrenLoading && displayableChildren.length > 0 && (
              <PrimaryButton onClick={exportChildrenHandler} primaryOrNot title={t("export_to_csv")} />
            )}
          </div>
        </header>

        <section className="w-full rounded mt-6 bg-white pb-6">
          <SearchSection
            onSearch={(input) => {
              setSearchValue(input);
              setPaginationConf({ ...paginationConf, pageIndex: 0 });
            }}
            onSliderClicked={() => setShowFilterSection((preShowFilterSection) => !preShowFilterSection)}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && <ChildrenFilterSection onApply={onApply} />}
          <hr className="h-[6px] bg-gray-f5 border-none" />
          <SelectedChildren setShowResendMessageModal={setShowResendMessageModal} />
          <main className="w-95/100 mx-auto relative">
            {showChildrenLoading && <LoadingModal isOpen={showChildrenLoading} />}

            {displayableChildren.length > 0 ? (
              <ChildrenTable
                data={displayableChildren}
                // columns={[..._columns, ...generateDynamicColumns()]}
                staticColumns={_columns}
                dynamicColumns={generateDynamicColumns()}
                paginationConf={paginationConf}
                setPaginationConf={setPaginationConf}
                pageCount={pageCount}
                totalDataCount={totalDataCount}
                listIsLoading={showChildrenLoading}
                pageChanged={(pageIndex, pageSize) => {
                  if (paginationConf.pageIndex !== pageIndex || paginationConf.pageSize !== pageSize) {
                    setPaginationConf({
                      ...paginationConf,
                      pageIndex: pageIndex,
                      pageSize: pageSize,
                    });
                  }
                }}
                currentSortConfig={sortConfig}
                sortChanged={setSortConfig}
              />
            ) : (
              !showChildrenLoading && <NoResultMessage noResultOrNoData={true} />
            )}
          </main>
        </section>
        <MainFooter />
      </main>

      {showResendMessageModal && (
        <ResendMessageModal
          loading={loading}
          onCancel={() => {
            setShowResendMessageModal(false);
            setLoading(false);
          }}
          onSend={(message) => {
            sendMessageHandler(message);
          }}
        />
      )}
    </MainContainer>
  );
};

interface ModalProps {
  isOpen: boolean;
}

const LoadingModal: React.FC<ModalProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 opacity-50"></div>
      <div className="relative bg-white rounded-lg p-4">
        <div className="flex flex-1 items-center justify-center">
          <CircularProgress size={64} style={{ color: "#11589a" }} />
        </div>
      </div>
    </div>
  );
};

export default Children;
