import useAPI from "../../hooks/useAPI";
import DefaulterDetailsType from "../../types/DefaulterDetails";
import { getFormattedDate } from "../../utils/DateUtils";

const DefaultersAPI = () => {
	const { sendRequest } = useAPI();

	const getDefaulters = (defaultersListPreccessResponse: (response: Response) => void, url: string) => {
		sendRequest(
			{
				url: url,
			},
			defaultersListPreccessResponse
		);
	};

	const resendReminder = (selectedDefaultersSlug: { slug: string }[], processResendReminderResponse: (response: Response) => void) => {
		sendRequest(
			{
				url: "send-reminders/",
				method: "POST",
				body: JSON.stringify(selectedDefaultersSlug),
			},
			processResendReminderResponse
		);
	};

	const getDefaulterDetails = (
		slug: string,
		processGetDefaulterDetailsResponse: (response: Response, defaulter: DefaulterDetailsType) => void
	) => {
		sendRequest(
			{
				url: `defaulters/${slug}/`,
			},
			async (response) => {
				const result = await response.json();

				const activityLogs = result.activity_log.map(
					(activity_log: {
						id: number;
						owner_name: string;
						owner_role: string;
						date: string;
						activity_text: string;
						owner_photo: string;
					}) => {
						return {
							id: activity_log.id,
							ownerName: activity_log.owner_name,
							ownerRole: activity_log.owner_role,
							date: activity_log.date,
							activityText: activity_log.activity_text,
							ownerPhoto: activity_log.owner_photo,
						};
					}
				);

				processGetDefaulterDetailsResponse(response, {
					firstName: result.first_name,
					lastName: result.last_name,
					nationalID: result.national_id,
					birthDate: result.birth_date,
					gender: result.gender,
					clinicTitle: result.clinic_title,
					phoneNumber: result.phone_number,
					area: result.area,
					email: result.email,
					street: result.street,
					caregiverFirstName: result.caregiver_first_name,
					caregiverLastName: result.caregiver_last_name,
					caregiverType: result.caregiver_type,
					caregiverTypeKey: result.caregiver_type_key,
					caregiverNationalID: result.caregiver_national_id,
					relation: result.relation,
					missedVaccines: result.missed_vaccines,
					activityLogs,
				});
			}
		);
	};

	const addActivityLog = (slug: string, note: string, processAddActivityLogResponse: (response: Response) => void) => {
		sendRequest(
			{
				url: `defaulters/${slug}/activity-logs/`,
				method: "POST",
				body: JSON.stringify({ activity_text: note }),
			},
			processAddActivityLogResponse
		);
	};

	const exportDefaulter = (
		searchTerm: string,
		clinicTitleSlug: string,
		lastReminderSentFrom: Date | null,
		lastReminderSentTo: Date | null,
		missedVaccinesScheduledDateFrom: Date | null,
		missedVaccinesScheduledDateTo: Date | null,
		exportPreccessResponse: (response: Response) => void
	) => {
		sendRequest(
			{
				url: `defaulters-admin/export/?${searchTerm && "search=" + searchTerm}${clinicTitleSlug && "&clinic_slug=" + clinicTitleSlug}${lastReminderSentFrom ? "&reminder_from=" + getFormattedDate(lastReminderSentFrom, false) : ""
					}${lastReminderSentTo ? "&reminder_to=" + getFormattedDate(lastReminderSentTo, false) : ""}${missedVaccinesScheduledDateFrom ? "&scheduled_from=" + getFormattedDate(missedVaccinesScheduledDateFrom, false) : ""
					}${missedVaccinesScheduledDateTo ? "&scheduled_to=" + getFormattedDate(missedVaccinesScheduledDateTo, false) : ""}`,
				method: "GET",

			},
			exportPreccessResponse
		);
	};

	return { getDefaulters, resendReminder, getDefaulterDetails, addActivityLog, exportDefaulter };
};

export default DefaultersAPI;
