import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DropdownMenu from "./DropdownMenu";
import CameroonLogo from "../../images/logo-v-cameroon.svg";
import LebanonLogo from "../../images/logo-v-lebanon.svg";
import RwandaLogo from "../../images/logo-v-rwanda.svg";
import { getHeaderTabs } from "../../utils/AdminUtils";

const MobileNavigationModal: React.FC<{
  sidebarClassName: string;
  backdropclassName: string;
  onBackdrop: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const selectLogo = () => {
    if (process.env.REACT_APP_Country === "Cameroon") {
      return CameroonLogo;
    } else if (process.env.REACT_APP_Country === "Lebanon") {
      return LebanonLogo;
    } else if (process.env.REACT_APP_Country === "Rwanda") {
      return RwandaLogo;
    }
  };

  return (
    <>
      <div
        className={`w-screen h-screen bg-gray-4147 fixed top-0 left-0 z-30 cursor-pointer md:hidden ${props.backdropclassName}`}
        onClick={() => props.onBackdrop()}
      />
      <aside className={`w-64 h-screen bg-blue-primary fixed top-0 left-0 duration-500 z-40 ${props.sidebarClassName}`}>
        <div className="bg-white flex justify-center py-14">
          <img src={selectLogo()} alt="CIMA" />
        </div>
        <nav className="flex flex-col mt-3">
          {getHeaderTabs().map((link, index) => (
            <NavLink
              key={index}
              activeClassName="bg-white51"
              className="text-white px-4 py-2 my-2 hover:bg-white51"
              to={link.route}
              onClick={() => props.onBackdrop()}
            >
              {t(link.title)}
            </NavLink>
          ))}
          {/* {process.env.REACT_APP_Country === "Cameroon" && (
            <DropdownMenu
              title={t("contact")}
              options={[
                { name: t("newsletter_subscribers"), location: "/subscribers" },
                {
                  name: t("training_candidates"),
                  location: "/training-candidates",
                },
              ]}
            />
          )} */}
          {/* The above section migrated to CIMA Admin panel */}

          <DropdownMenu
            title={t("reports")}
            options={[
              {
                name: t("vaccine_utilization"),
                location: "/vaccine-utilization",
              },
            ]}
          />

          <DropdownMenu
            title={t("settings")}
            options={[
              { name: t("health_messages"), location: "/health-messages" },
              {
                name: t("vaccination_details"),
                location: "/vaccination-details",
              },
              { name: t("areas"), location: "/areas" },
            ]}
          />
        </nav>
      </aside>
    </>
  );
};

export default MobileNavigationModal;
