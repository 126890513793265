import useAPI from "../../hooks/useAPI";

const ChildrenAPI = () => {
  const { sendRequest } = useAPI();

  const getChildren = (childrenPreccessResponse: (response: Response) => void, url: string) => {
    sendRequest(
      {
        url: url,
      },
      childrenPreccessResponse
    );
  };

  const sendMessage = (
    selectedDefaultersSlug: { slug: string }[],
    message: string,
    processResendReminderResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: "send-multiple-message/",
        method: "POST",
        body: JSON.stringify({ slugs: selectedDefaultersSlug, message: message }),
      },
      processResendReminderResponse
    );
  };

  const importNewBornsAPI = (
    clinic_slug: string,
    formData: FormData,
    importNewBornsProcessResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `import-newborns/${clinic_slug}/`,
        contentType: true,
        method: "POST",
        body: formData,
      },
      importNewBornsProcessResponse
    );
  };

  const importFollowUpsAPI = (
    clinic_slug: string,
    formData: FormData,
    importNewBornsProcessResponse: (response: Response) => void
  ) => {
    sendRequest(
      {
        url: `import-followups/${clinic_slug}/`,
        contentType: true,
        method: "POST",
        body: formData,
      },
      importNewBornsProcessResponse
    );
  };

  const exportChildren = (queryParams: string, exportPreccessResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `children-list/export/${queryParams}`,
        method: "GET",
      },
      exportPreccessResponse
    );
  };

  const getChild = (
    child_slug: string,
    getProcessResponse: (response: Response) => void,
  ) => {
    sendRequest(
      {
        url: `children-list/${child_slug}/`,
        method: "GET",
      },
      getProcessResponse
    );
  };

  const getChildMessageHistory = (
    child_slug: string,
    queryParams: string,
    getProcessResponse: (response: Response) => void,
  ) => {
    sendRequest(
      {
        url: `children-list/${child_slug}/view-message-history/${queryParams}`,
        method: "GET",
      },
      getProcessResponse
    );
  };

  const getDropdownsData = (
    getProcessResponse: (response: Response) => void,
  ) => {
    sendRequest(
      {
        url: `children-list/types/`,
        method: "GET",
      },
      getProcessResponse
    );
  };

  return { getChildren, importNewBornsAPI, importFollowUpsAPI, sendMessage, exportChildren, getChild, getChildMessageHistory, getDropdownsData };
};
export default ChildrenAPI;
