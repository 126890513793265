import React, { useMemo, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useTable, Column, usePagination } from "react-table";
import Defaulter from "../../../types/Defaulter";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import DefaulterRow from "./DefaulterRow";
import Pagination from "../../UI/Pagination";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { PaginationConfig, SortConfig } from "./Defaulters";

const DefaulterTable: React.FC<{
  data: Defaulter[];
  paginationConf: PaginationConfig,
  setPaginationConf: Dispatch<SetStateAction<PaginationConfig>>,
  pageCount: number;
  totalDataCount: number;
  currentSortConfig: SortConfig | undefined;
  pageChanged: (pageIndex: number, pageSize: number) => void;
  sortChanged: (sortConfig: SortConfig) => void;
}> = ({
  data,
  paginationConf,
  setPaginationConf,
  pageCount = 0,
  totalDataCount,
  currentSortConfig,
  pageChanged,
  sortChanged,
}) => {
    const { t } = useTranslation();
    const columns: Column<Defaulter>[] = useMemo(
      () => [
        {
          header: t("name"),
          accessor: "full_name",
        },
        {
          header: t("phone_number"),
          accessor: "phone",
        },
        {
          header: t("national_id"),
          accessor: "national_id",
        },
        {
          header: t("clinic"),
          accessor: "clinic_name",
        },
        {
          header: t("area"),
          accessor: "area",
        },
        {
          header: t("missed_vaccines"),
          accessor: "missed_vaccines",
          disableSortBy: true,
        },
        {
          header: t("last_reminder_sent"),
          accessor: "last_reminder_sent",
        },
      ],
      [t]
    );

    const { getTableProps, getTableBodyProps, headerGroups } = useTable(
      {
        columns,
        data,
      },
      usePagination
    );

    const { selectedDefaulters, selectAll, deselectAll } = useContext(SelectedDefaultersContext);

    useEffect(() => {
      pageChanged(paginationConf.pageIndex, paginationConf.pageSize);
    }, [paginationConf.pageSize, paginationConf.pageIndex]);

    const visibleDefaulters = data.map((row) => row.slug);
    let allChecked = true;
    for (let visibleDefaulter of visibleDefaulters) {
      if (selectedDefaulters.indexOf(visibleDefaulter) === -1) {
        allChecked = false;
        break;
      }
    }
    if (selectedDefaulters.length === 0) {
      allChecked = false;
    }

    const sortTable = (column: string, isAscending: boolean) => {
      sortChanged({
        column: column,
        sortType: isAscending ? "Ascending" : "Descending",
      });
      setPaginationConf({ ...paginationConf, pageIndex: 0 });
    };

    return (
      <div className="relative">
        <div className="overflow-x-auto overflow-y-hidden scroll-firefox">
          <table {...getTableProps()} className="w-full border-separate border-spacing-0">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                  {headerGroup.headers.map((column, index) => (
                    <th
                      scope="col"
                      className={`td-firefox border-gray-df table__cell sticky top-0 z-10 text-sm text-[#414141] font-extrabold tracking-wider ${index === 0 && `z-20 ${i18next.language === "ar" ? "right-0 border-l" : "left-0 border-r"}`
                        }`}
                      {...column.getHeaderProps()}
                    >
                      <div className="flex items-center text-sm">
                        {index === 0 && (
                          <div
                            className={`table__checkbox grid place-items-center ${allChecked &&
                              "border-blue-primary bg-blue-primary"
                              } ${i18next.language === "ar" ? "ml-4" : "mr-4"}`}
                            onClick={(e) => {
                              e.stopPropagation();

                              if (allChecked) {
                                deselectAll(data.map((row) => row.slug));
                              } else {
                                selectAll(data.map((row) => row.slug));
                              }
                            }}
                          >
                            {allChecked && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-sm text-white"
                              />
                            )}
                          </div>
                        )}

                        <h3 className=" font-bold text-black41">{column.render("header")}</h3>
                        {!column.disableSortBy &&
                          <div className="flex flex-col mx-4">
                            <FontAwesomeIcon
                              className={`cursor-pointer ${currentSortConfig?.column === column.id && currentSortConfig?.sortType === "Ascending"
                                ? "text-black41"
                                : "text-gray-df"
                                }`}
                              icon={faAngleUp}
                              onClick={() => sortTable(column.id, true)}
                            />
                            <FontAwesomeIcon
                              className={`cursor-pointer ${currentSortConfig?.column === column.id && currentSortConfig?.sortType === "Descending"
                                ? "text-black41"
                                : "text-gray-df"
                                }`}
                              icon={faAngleDown}
                              onClick={() => sortTable(column.id, false)}
                            />
                          </div>
                        }
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.map((defaulter) => {
                return <DefaulterRow defaulter={defaulter} />;
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          dropdownKeys={["5", "10", "20"]}
          previousPage={() =>
            setPaginationConf((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex - 1 }))
          }
          nextPage={() => setPaginationConf((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex + 1 }))}
          setPageSize={(pageSize) => setPaginationConf((preValue) => ({ ...preValue, pageSize: pageSize }))}
          gotoPage={(_pageNum) =>
            setPaginationConf((preValue) => ({ ...preValue, pageIndex: _pageNum as number }))
          }
          pageCount={pageCount}
          pageIndex={paginationConf.pageIndex}
          pageSize={paginationConf.pageSize}
          pageLength={data.length}
          totalLength={totalDataCount}
        />
      </div>
    );
  };

export default DefaulterTable;
