import { Switch, Route, Redirect } from "react-router-dom";
import SelectedClinicsContextProvider from "./store/SelectedClinicsContext";
import ProfileContext from "./store/ProfileContext";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./components/main/dashboard/Dashboard";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import SearchFilterClinicsContextProvider from "./store/SearchFilterClinicsContext";
import Clinics from "./components/main/clinics/Clinics";
import SearchFilterDefaultersContextProvider from "./store/SearchFilterDefaultersContext";
import Defaulters from "./components/main/defaulters/Defaulters";
import DefaulterDetails from "./components/main/defaulters/defaulter/DefaulterDetails";
import StaffManagment from "./components/main/staff/StaffManagment";
import ProfilePage from "./components/main/profile/ProfilePage";
import AccountSettingPage from "./components/main/profile/AccountSettingPage";
import VaccineDetail from "./components/main/setting/vaccine/VaccineDetail";
import HealthMessage from "./components/main/setting/healthMessage/HealthMessage";
import NotFound404 from "./components/pages/not-found-404/NotFound404";
import "./App.css";
import { staffLoader } from "./components/main/containers/staffLoader";
import { profileLoader } from "./components/main/containers/profileLoader";
import { accountSettingLoader } from "./components/main/containers/accountSettingLoader";
import { healthMessageLoader } from "./components/main/containers/healthMessageLoader";
import { vaccineDetailLoader } from "./components/main/containers/vaccineDetailLoader";
import SelectedDefaultersContextProvider from "./store/SelectedDefaultersContext";
import SelectedChildrenContextProvider from "./store/SelectedChildrenContext";
import StaffContextProvider from "./store/StaffContext";
import VaccineContextProvider from "./store/VaccineContext";
import HealthMessageContextProvider from "./store/HealthMessageContext";
import AreaContextProvider from "./store/AreaContext";
import AccountSettingContextProvider from "./store/AccountSettingContext";
import Children from "./components/main/children/Children";
import SearchFilterChildrenContextProvider from "./store/SearchFilterChildrenContext";
import Areas from "./components/main/setting/areas/Areas";
import AddOrEditArea from "./components/main/setting/areas/AddOrEditArea";
import VaccineUtilizationContextProvider from "./store/VaccineUtilizationContext";
import VaccineUtilization from "./components/main/reports/vaccineUtilization/VaccineUtilization";
import SelectedVaccineUtilizationContextProvider from "./store/SelectedVaccineUtilizationContext";
import ClinicDetails from "./components/main/clinics/ClinicDetails";
import useRole from "./hooks/useRole";
import MessageManagement from "./components/main/messaging/MessageManagement";
import AddMessage from "./components/main/messaging/AddMessage";
import PreviewMessage from "./components/main/messaging/PreviewMessage";
import MessageHistory from "./components/main/children/message_history/MessageHistory";

export const isProductionServer =
  window.location.href.includes("app.cameroon.cima.care") ||
  window.location.href.includes("app.kura.cima.care") ||
  window.location.href.includes("app.lebanon.cima.care");

export const isLebanonVersion = process.env.REACT_APP_Country === "Lebanon";

const App: React.FC = () => {
  const { isClinicAdmin } = useRole();
  const StaffManagmentWrapped = staffLoader(StaffManagment);
  const ProfilePageWrapped = profileLoader(ProfilePage);
  const AccountSettingPageWrapped = accountSettingLoader(AccountSettingPage);
  const HealthMessageWrapped = healthMessageLoader(HealthMessage);
  const VaccineDetailWrapped = vaccineDetailLoader(VaccineDetail);

  return (
    <Switch>
      <PrivateRoute path="/" exact={true}>
        <Redirect to="/dashboard" />
      </PrivateRoute>
      <PrivateRoute path="/dashboard" exact={true}>
        <Dashboard />
      </PrivateRoute>

      {isClinicAdmin() ? (
        <PrivateRoute path="/clinic-details" exact={true}>
          <SearchFilterClinicsContextProvider>
            <ClinicDetails />
          </SearchFilterClinicsContextProvider>
        </PrivateRoute>
      ) : (
        <PrivateRoute path="/clinics" exact={true}>
          <SearchFilterClinicsContextProvider>
            <SelectedClinicsContextProvider>
              <Clinics />
            </SelectedClinicsContextProvider>
          </SearchFilterClinicsContextProvider>
        </PrivateRoute>
      )}

      <PrivateRoute path="/clinics/:slug/staff" exact={true}>
        <StaffContextProvider>
          <StaffManagmentWrapped />
        </StaffContextProvider>
      </PrivateRoute>

      <PrivateRoute path="/defaulters" exact={true}>
        <SearchFilterDefaultersContextProvider>
          <SelectedDefaultersContextProvider>
            <Defaulters />
          </SelectedDefaultersContextProvider>
        </SearchFilterDefaultersContextProvider>
      </PrivateRoute>

      <PrivateRoute path="/defaulters/:slug" exact={true}>
        <DefaulterDetails />
      </PrivateRoute>

      <PrivateRoute path="/children" exact={true}>
        <SearchFilterChildrenContextProvider>
          <SelectedChildrenContextProvider>
            <Children />
          </SelectedChildrenContextProvider>
        </SearchFilterChildrenContextProvider>
      </PrivateRoute>

      {!isClinicAdmin() && (
        <PrivateRoute path="/message-history/:slug" exact={true}>
          <MessageHistory />
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/messaging" exact={true}>
          <MessageManagement />
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/messaging/add" exact={true}>
          <AddMessage />
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/messaging/view" exact={true}>
          <PreviewMessage />
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/vaccine-utilization" exact={true}>
          <VaccineUtilizationContextProvider>
            <SelectedVaccineUtilizationContextProvider>
              <VaccineUtilization />
            </SelectedVaccineUtilizationContextProvider>
          </VaccineUtilizationContextProvider>
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/health-messages" exact={true}>
          <HealthMessageContextProvider>
            <HealthMessageWrapped />
          </HealthMessageContextProvider>
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/vaccination-details" exact={true}>
          <VaccineContextProvider>
            <VaccineDetailWrapped />
          </VaccineContextProvider>
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/areas" exact={true}>
          <AreaContextProvider>
            <Areas />
          </AreaContextProvider>
        </PrivateRoute>
      )}
      {!isClinicAdmin() && (
        <PrivateRoute path="/area/:id?" exact={true}>
          <AddOrEditArea />
        </PrivateRoute>
      )}

      <PrivateRoute path="/account-profile" exact={true}>
        <ProfileContext>
          <ProfilePageWrapped />
        </ProfileContext>
      </PrivateRoute>
      <PrivateRoute path="/account-settings" exact={true}>
        <AccountSettingContextProvider>
          <AccountSettingPageWrapped />
        </AccountSettingContextProvider>
      </PrivateRoute>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password/:uid/:hashToken">
        <ResetPassword />
      </Route>
      <Route path="*">
        <NotFound404 />
      </Route>
    </Switch>
  );
};

export default App;
